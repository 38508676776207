/* eslint-disable */
/**
 * Description: JS for papersmart.net
 * Version: 1.0.0
 * Last update: 2015/08/01
 * Author: UVOCorp
 */

document.addEventListener('DOMContentLoaded', function () {
  // fixed header
  var headerFullHeight = $(".tagline_wrapper").height() + $(".top-block-row:nth-of-type(1)").height() + $(".top-block-row:nth-of-type(2)").height();
  if ($(".tagline_wrapper").length < 1) {
    headerFullHeight = 200;
  }

  $(window).on('scroll', function() {
    if ($(window).width() > 767) {
      if ($(window).scrollTop() > headerFullHeight) {
        $(".topmain-full").addClass("header-fixed");
      } else {
        if ($(".topmain-full").addClass("header-fixed")) {
          $(".topmain-full").removeClass("header-fixed");
        }
      }
    }
  });

  if ($(window).width() < 768) {
    $(".manage-box").appendTo(".mobile-account");
    $(".manage-box").fadeIn(200);
  }

  $( window ).resize(function() {
    if ($(window).width() < 768) {
      $(".manage-box").appendTo(".mobile-account");
      $(".manage-box").fadeIn(200);
    }
  });



	//toplink click
	$('.totop-link').click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	//toggle topmenu
	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
		$('body').toggleClass('body-overflow');
		var cur_menu = $('.collapse[data-toggle=' + $(this).attr('data-menu') +
			']');
		if ($(cur_menu).css('display') == 'none') {
			$(cur_menu).show();
		} else {
			$(cur_menu).hide();
		}
		return false;
	});

	$('.topmenu-nav-link').click(function(event) {
		event.stopPropagation();
		$(this).toggleClass('open');
		$(this).children('nav').toggleClass('open-nav');
	});

	if ($('.topmenu-line').length > 0) {
		var tmenu = $('.topmenu-line'),
			tmenuTop = $('.topmenu-line').offset().top;
	}

	$(window).scroll(function() {
		//fixed menu
		var scrlTopPos = $(window).scrollTop(),
			windowWidth = parseInt($(window).width()) + 17;
		if (windowWidth >= 968) {
			if (scrlTopPos <= tmenuTop) {
				$(tmenu).removeClass('fixed');
			} else if (scrlTopPos > tmenuTop) {
				$(tmenu).addClass('fixed');
			}
		}
		//show to-top link
		if ($(window).scrollTop() >= 200) {
			$('.totop-link').fadeIn();
		} else {
			$('.totop-link').fadeOut();
		}
	});

	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});

	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');


});


(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '38',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  if (document.querySelector('.faq__component')) {
    window.addEventListener('DOMContentLoaded', toggle_list);
  }

  if (document.querySelector('[data-page="frequently_asked_questions"]') || document.querySelector('[data-page="money_back_guarantee"]')){
    window.addEventListener('contentRendered', toggle_list);
  }
})();
